import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGripHorizontal,
  faLayerPlus,
  faAnalytics,
  faMagic,
  faBullseyeArrow,
  faCode,
} from "@fortawesome/pro-duotone-svg-icons"

export default function ServiceIcon({ icon, ...props }) {
  switch (icon) {
    case "Visual Data Analytics and Dashboarding":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faAnalytics}
          {...props}
        />
      )

    case "Data Strategy":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faBullseyeArrow}
          {...props}
        />
      )

    case "Data Preparation":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faGripHorizontal}
          {...props}
        />
      )

    case "Data Collection":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faLayerPlus}
          {...props}
        />
      )
    case "Services Beyond":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faMagic}
          {...props}
        />
      )

    case "Web Design and Development":
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faCode}
          {...props}
        />
      )

    case "Other Services":
    default:
      return (
        <FontAwesomeIcon
          size="1x"
          className="text-purple-600"
          icon={faMagic}
          {...props}
        />
      )
  }
}
